import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { FormsModule } from '@angular/forms';

// locale
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt, 'pt');

// modules
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MegaleiosAlertModule } from './utils/modules/megaleios-alert/megaleios-alert.module';
import { NgxMaskModule } from 'ngx-mask';

// interceptors
import { JsonWebTokenInterceptor } from 'src/app/utils/interceptors/json-web-token/json-web-token.interceptor';
import { ErrorInterceptor } from 'src/app/utils/interceptors/error/error.interceptor';

import { environment } from '../environments/environment';


// containers
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    // containers
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // modules
    HttpClientModule,
    MegaleiosAlertModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    RecaptchaV3Module
  ],
  providers: [
    // locale
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    // interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JsonWebTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
